import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { Link } from "gatsby";
import { Container, Col, Row } from "react-bootstrap";

const Page404 = (props) => {
  return (
    <React.Fragment>
    <Header />
      <section className="errorpage">
        <Container>
          <Row className="d-flex justify-content-center bd-highlight mb-3 text-center">
            <Col md="9">
              <h1>We’re sorry, the page you were looking for cannot be found.</h1>
              <p>This maybe because it has been removed, we’ve changed its name or it is temporarily unavailable.</p>
              <div class="btn-wrapper">
                <Link to="/property/for-sale/in-dubai//" class="btn btn-black">Find a property</Link>
                <Link to="/" class="btn btn-white share">Homepage</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </React.Fragment>
  )
}
export default Page404;

